import React from 'react';
import { Navigate } from 'react-router-dom';

const NotFound = (props) => {

    return <Navigate to="/" />;

    // return (
    //     <div className="row">           
    //         <div className="col-12">
    //             <h1 className="page-title">404 Not Found</h1>
    //         </div>
    //     </div>
    // )
}

export default NotFound;