export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('');
}

export const formatTime = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const convertTo24HrsFormat = (time) => {
    const slicedTime = time.split(/(PM|AM)/gm)[0];
 
    let [hours, minutes] = slicedTime.split(':');
 
    if (hours === '12') {
       hours = '00';
    }
 
    let updateHourAndMin;
 
    function addition(hoursOrMin) {
       updateHourAndMin =
          hoursOrMin.length < 2
             ? (hoursOrMin = `${0}${hoursOrMin}`)
             : hoursOrMin;
 
       return updateHourAndMin;
    }
 
    if (time.endsWith('PM')) {
       hours = parseInt(hours, 10) + 12;
    }
 
    return (`${addition(hours)}:${addition(minutes)}`).trim();
}

export const getDifferenceInDays = (date1, date2) => {
    let diffInMs = date1.getTime() - date2.getTime(); 
    // console.log(date1 , 'date1');
    // console.log(date2 , 'date2');
    // console.log(date1.getTime() , 'date1.getTime()');
    // console.log(date2.getTime() , 'date2.getTime()');
    // console.log(date1.toString() , 'date1.toString()');
    // console.log(date2.toString() , 'date2.toString()');
    // console.log(diffInMs, 'diffInMs');
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
}