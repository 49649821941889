import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconNote } from '../icons/ico-note.svg';
import { get_notes } from '../helpers/api.service';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import AddNote from './AddNote';
import Dropdown from 'react-bootstrap/Dropdown';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';
import VoiceGallery from './VoiceGallery';

import { ReactComponent as IconEdit } from '../icons/ico-edit.svg';
import { ReactComponent as IconTrash } from '../icons/ico-trash.svg';
import { ReactComponent as IconDots } from '../icons/ico-dots.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';

import { deleteNote, setEditNote, setViewNote } from '../slices/userSlice';
import EditNote from './EditNote';
import ViewNote from './ViewNote';

const Note = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const [isSearching, setIsSearching] = useState(0);
    const [error, setError] = useState('');
    const [viewNote, setViewNote] = useState(false);

    const [viewGallery, setViewGallery] = useState(false);
    const [viewVideos, setViewVideos] = useState(false);
    const [viewVoices, setViewVoices] = useState(false);

    const loadData = () => {
        setIsSearching(1);
        get_notes(search, userState.credentials.refresh_token).then(res => {
            if (res.data.data.notes != null) {
                setIsSearching(0);
                setData(res.data.data.notes);
            }
        }).catch((err) => {
            setIsSearching(0);
            setError(err.message);
        });
    }

    useEffect(() => {
        loadData();
    }, [search])

    const doDeleteNote = (note_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'You will delete note',
            buttons: [
            { label: 'Yes', onClick: () => {
                dispatch(deleteNote(note_id)).then((e) => {
                    
                    if( e.type === 'user/deleteNote/fulfilled'){

                        toast.success('Note deleted', {
                            toastId: 'delete-note-success',
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });  

                        //refresh notes
                        loadData();
                        
                    }else if(e.type === 'user/deleteNote/rejected'){
                        toast.success('Delete note failed', {
                            toastId: 'delete-note-success',
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });   
                    }
                })
            } },
            { label: 'No', onClick: () => {} }
            ]
        });
    }

    const resetNote = () => {
        setViewNote(false);
    }

    const resetGallery = () => {
        setViewGallery(false);
    }

    const resetVideos = () => {
        setViewVideos(false);
    }

    const resetVoices = () => {
        setViewVoices(false);
    }

    return (
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Notes</h1>

                <div className="search-wrap search-note">
                    <Form onSubmit={(note) => note.preventDefault()}>
                        <Form.Group className="mb-3">
                            <IconSearch />
                            <Form.Control type="text" placeholder="" value={search} onChange={(note) => setSearch(note.target.value)} />
                        </Form.Group>
                        
                        <div className="result-count">{data.length == 1 ? '1 result' : (typeof data.length !== 'undefined' ? data.length : '0') + ' results' }</div>
                    </Form>
                </div>

                <div className="item-list list-note">
                    {isSearching === 1 && <p className="text-center">Loading...</p>}
                    {isSearching === 0 && Object.entries(data).map((obj, index) => {
                        let note = obj[1];
                        // console.log('note', note)
                        return (
                            <li key={index}>
                                {/* <div className="item-header highlight"><span className="item-icon highlight"><IconNote /></span><a target="_blank" href={note.url}>{note.title}</a></div> */}
                                <div onClick={() => {
                                    // dispatch(setViewNote(note));
                                    setViewNote(note);
                                }} className="item-header highlight"><span className="item-icon highlight"><IconNote /></span>{note.title}</div>
                                                                
                                <ul className="media-view">
                                    {/* {d.videos && <li><IconCamera /></li>} */}
                                    {note.photos.length > 0 && <li><IconPhoto onClick={()=>{setViewGallery(note)}}/></li>}
                                    {note.videos.length > 0 && <li><IconVideo onClick={()=>{setViewVideos(note)}}/></li>}
                                    {note.voices.length > 0 && <li><IconVoice onClick={()=>{setViewVoices(note)}}/></li>}
                                    {/* <li><IconAttachment /></li> */}
                                </ul>
                                
                                <div className="item-desc">{note.date_formatted}</div>

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" className={'dropdown-toggle' + ' dropdown-' + note.post_id }>
                                        <IconDots />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={(e) => {dispatch(setEditNote(note)); e.preventDefault();}}>Edit <IconEdit /></Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={(e) => {doDeleteNote(note.post_id); e.preventDefault();}}>Delete <IconTrash /></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>)
                    })}
                    {isSearching === 0 && data.length === 0 && <p className="text-center">Not found</p>}
                    {isSearching === 0 && typeof data.length === 'undefined' && error === '' && <p className="text-center">Note not available</p>}
                    {isSearching === 0 && error !== '' && <Alert className="mt-3" variant="warning">{error}</Alert>}
                </div>

                <AddNote />
                <EditNote note={userState.note.editNote} />
                {/* <ViewNote note={userState.note.viewNote} /> */}
                <ViewNote note={viewNote} resetNote={resetNote} />
                <ImageGallery event={viewGallery} resetGallery={resetGallery} />
                <VideoGallery event={viewVideos} resetGallery={resetVideos} />
                <VoiceGallery event={viewVoices} resetGallery={resetVoices} />
            </div>
        </div>
    )
}

export default Note;