import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { ReactComponent as IconPlay } from '../icons/ico-play.svg';
import { ReactComponent as IconPause } from '../icons/ico-pause.svg';

// import Player from "react-howler-player";
// import ReactAudioPlayer from 'react-audio-player';
// import ReactPlayer from 'react-player'
import { AudioPlayerProvider, useAudioPlayer  } from "react-use-audio-player"
// import AudioPlayer from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';
// import '../css/calendar.css';
// import $ from 'jquery';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const VoiceGallery = (props) => {

    const [eventObj, setEventObj] = useState(props.event);

    const [VoiceGalleryPopup, setVoiceGalleryPopup] = useState(eventObj!==false && eventObj.post_id);

    const closeViewPopup = () => {
        setVoiceGalleryPopup(false);        
        props.resetGallery();
    }

    const AudioPlayer = ({ file }) => {
        const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
            src: file,
            // format: "mp3",
            autoplay: false,
            onend: () => console.log("sound has ended!")
        })
    
        if (!ready && !loading) return <div className="howler-player"><div className="howler-filename"><span className="not-supported">FIle is not supported!</span></div></div>
        if (loading) return <div className="howler-player"><div className="howler-filename">Loading audio...</div></div>

        const filename = file.split('/').pop();
    
        return (
            <div className="howler-player">
                <div className="howler-filename">
                    {filename}
                </div>
                <button disabled={false} className="howler-control" onClick={togglePlayPause}>{playing ? <IconPause /> : <IconPlay />}</button>
                <div className="clearfix"></div>
            </div>
        )
    }

    const VoiceGalleryPopupElement = () => {
        
        if(!VoiceGalleryPopup){
            return (<></>);
        }
        
        return (
            <Popup className="view-gallery" open={VoiceGalleryPopup} position="center center" closeOnDocumentClick={false}>                
                <a className="close-popup btn-close" onClick={()=> closeViewPopup()}></a>
                <div className="form-wrap">
                    <h3>Voices</h3>                    
                    <div className="view-media text-center">
                        {eventObj.voices.map((voice, index) => { 
                            return <div className='item' key={index}>
                                {/* <audio controls>
                                    <source src={voice} />
                                    Your browser does not support the audio element.
                                </audio> */}
                                {/* <ReactAudioPlayer 
                                    src={voice}
                                    autoPlay
                                    controls
                                    loop
                                /> */}
                                {/* <AudioPlayer 
                                    src={voice}
                                    autoPlay={false}
                                    loop={false}
                                /> */}
                                {/* <ReactPlayer 
                                    url={voice}
                                    config={{
                                        file: {
                                            forceAudio: true
                                        }
                                    }}
                                /> */}
                                <AudioPlayerProvider>
                                    <AudioPlayer file={voice} />
                                </AudioPlayerProvider>
                                {/* <Player
                                    src={voice}
                                    isDark={false}
                                    // onTimeUpdate={timeUpdate}
                                    // onLoad={onPlayerReady}
                                /> */}
                            </div>
                        })}
                    </div>
                </div>
            </Popup>
        );
    }

    useEffect( () => {
        setEventObj(props.event);
        setVoiceGalleryPopup(props.event!==false && props.event.post_id);
    }, [props.event])

    return (
        <>
            {VoiceGalleryPopupElement()}
        </>
    )
}

export default VoiceGallery;