import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconFile } from '../icons/ico-file.svg';
// import {listReactFiles} from 'list-react-files'
import { get_documents } from '../helpers/api.service';
import { Link } from 'react-router-dom';

const Document = (props) => {

    const userState = useSelector(state => state.user);

    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const [isSearching, setIsSearching] = useState(0);

    const loadData = () => {
        setIsSearching(1);
        get_documents(search, userState.credentials.refresh_token).then(res => {
            if(res.data.data.docs!=null){                
                setIsSearching(0);
                setData(res.data.data.docs);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, [search])

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Documents</h1>

                <div className="search-wrap search-document">
                    <Form onSubmit={(event)=> event.preventDefault()}>
                        <Form.Group className="mb-3">
                            <IconSearch />
                            <Form.Control type="text" placeholder="" value={search} onChange={(event) => setSearch(event.target.value) } />
                        </Form.Group>
                    </Form>
                </div>

                <div className="item-list list-document">
                    {isSearching === 1 && <p className="text-center">Loading...</p>}
                    {isSearching === 0 && data.length === 0 && <p className="text-center">Not found</p>}
                    { isSearching === 0 && Object.entries(data).map((obj, index) => {
                    let doc = obj[1];
                    return (<li key={index}>
                        <div className="item-header"><span className="item-icon highlight"><IconFile /></span>{doc.name}</div>
                        {/* <div className="item-desc"><a target="_blank" href={doc.url}>DOWNLOAD</a></div> */}
                        <div className="item-desc"><Link to={'/pdf/' + btoa(doc.url)}>VIEW</Link></div>
                    </li> )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Document;