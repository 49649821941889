import React from 'react';

const ContactForm = (props) => {

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Contact Form</h1>
            </div>
        </div>
    )
}

export default ContactForm;