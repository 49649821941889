import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../css/calendar.css';
import CalendarMonth from './CalendarMonth';
import AddEvent from './AddEvent';
import EditEvent from './EditEvent';
import Papa from 'papaparse';
import Dropdown from 'react-bootstrap/Dropdown';
import { 
    getEvents, 
    resetCalendarLoaded, 
    setMonth, setYear, 
    setGetEventPromise, 
    setCalendarFirstLoaded, 
    setFullMode, 
    setCalendarLegends,
    setHoliday,
    deleteEvent,
    setDayEvents,
    setEditEvent,
    setActiveDate,
    setDayLegends,
    setHolidayState,
} from '../slices/userSlice';
import { formatDate } from '../helpers/time';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { downloadIcs } from '../helpers/ics';

import { ReactComponent as IconLeft } from '../icons/ico-left.svg';
import { ReactComponent as IconRight } from '../icons/ico-right.svg';
import { ReactComponent as IconLayer } from '../icons/ico-layer.svg';
import { ReactComponent as IconMoon } from '../icons/ico-moon.svg';
import { ReactComponent as IconDots } from '../icons/ico-dots.svg';
import { ReactComponent as IconShare } from '../icons/ico-share.svg';
import { ReactComponent as IconEdit } from '../icons/ico-edit.svg';
import { ReactComponent as IconTrash } from '../icons/ico-trash.svg';
import { ReactComponent as IconIcs } from '../icons/ico-ics.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconCamera } from '../icons/ico-camera.svg';
import { ReactComponent as IconAttachment } from '../icons/ico-attachment.svg';
import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';
import VoiceGallery from './VoiceGallery';

const csvFile = require('../csv/holiday-2022-2027.csv');

const Calendar = (props) => {
    
    const userState = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [viewGallery, setViewGallery] = useState(false);
    const [viewVideos, setViewVideos] = useState(false);
    const [viewVoices, setViewVoices] = useState(false);

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      
    const monthLabel = months[userState.calendar.month-1];
    const yearLabel = userState.calendar.year;

    const changeCalendar = (to) => {
        let newMonth = userState.calendar.month + to;
        let newYear = userState.calendar.year;

        if(newMonth < 1){
            newMonth = 12;
            newYear--;
        }else if(newMonth > 12){
            newMonth = 1;
            newYear++;
        }

        dispatch(setMonth(newMonth));
        dispatch(setYear(newYear));

        if(userState.calendar.getEventPromise!=null){
            userState.calendar.getEventPromise.abort();
            // console.log(userState.calendar.getEventPromise);
        }
        
        dispatch(resetCalendarLoaded());

        const premise = dispatch(getEvents({
            'year': newYear,
            'month': newMonth,
        }))

        // dispatch( setGetEventPromise(premise));
    }

    const typeLabel = (type) => {
        let label = type;

        switch(type){
            case 'note': label = 'Note'; break;
            case 'event': label = 'Event'; break;
            case 'annual': label = 'Annual Leave'; break;
            case 'dayoff': label = 'Day Off'; break;
            case 'training': label = 'Training'; break;
            case 'ot': label = 'Recall/OT'; break;
            default: label = type; break;
        }

        return label;
    }

    const doDeleteEvent = (event_id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'You will delete event/note',
            buttons: [
            { label: 'Yes', onClick: () => {
                dispatch(deleteEvent(event_id)).then((e) => {
                    
                    if( e.type === 'user/deleteEvent/fulfilled'){

                        toast.success('Event deleted', {
                            toastId: 'delete-event-success',
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });   
                        
                        //refresh calendar
                        dispatch(resetCalendarLoaded());
                        dispatch(getEvents({
                            'year': userState.calendar.year,
                            'month': userState.calendar.month,
                        })).then( (e) => {
                            
                            if( e.payload.code === 'success'){
                                //refresh selected day events
                                let date = userState.calendar.activeDate;
                                let monthEvents = e.payload.data.events;
                                let events = monthEvents[date] ? monthEvents[date] : [];

                                let day = new Date(date).getDate(); //xxx
                                let isHoliday = userState.calendar.holiday !== null ? ( typeof userState.calendar.holiday[date] === 'undefined' ? false : true ) : false;
                                let shift = userState.calendar.rooster[day];                    
                                let isDayOt = false;
                                let isNightOt = false;
                                let isDayOff = false;
                                let isLeave = false;

                                events.map(d=>{
                                    if(d.type==='ot' && d.shift==='day'){
                                        isDayOt = true;
                                    }else if(d.type==='ot' && d.shift==='night'){
                                        isNightOt = true;
                                    }else if(d.type==='dayoff'){
                                        isDayOff=true;
                                    }else if(d.type==='annual'){
                                        isLeave=true;
                                    }
                                })

                                if( events.length ){
                                    changeDayEvents(date, events, shift.day, shift.night, isHoliday, isDayOt, isNightOt, isDayOff, isLeave)
                                }
                            }
                        }); 
                    }else if(e.type === 'user/deleteEvent/rejected'){
                        toast.success('Delete event failed', {
                            toastId: 'delete-event-success',
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });   
                    }
                })
            } },
            { label: 'No', onClick: () => {} }
            ]
        });
    }

    const changeDayEvents = (fullDate, events, dayPlatoon, nightPlatoon, isHoliday, isDayOt, isNightOt, isDayOff, isLeave) => {
        dispatch(setActiveDate(fullDate));
        dispatch(setDayEvents(events));
        dispatch(setDayLegends({
            dayPlatoon,
            nightPlatoon,
            isHoliday,
            isDayOt,
            isNightOt,
            isDayOff,
            isLeave,
        }));
    }

    useEffect(()=>{
        
        if(! userState.calendar.isFirstLoaded){
            dispatch(getEvents({
                'year': userState.calendar.year,
                'month': userState.calendar.month,
            }));    
         }

        //  console.log('calendar state',userState.calendar)

         dispatch(setCalendarFirstLoaded());
    }, [])

    useEffect(() => {
        if( userState.calendar.holiday === null ){
            Papa.parse(csvFile, {
                header: true,
                download: true,
                skipEmptyLines: true,
                complete: function (results) {
                    let holidays = [];
                    results.data.forEach((holiday) => {
                        let date = formatDate(new Date(holiday.date));

                        let holidayData = {
                            event: holiday.event,
                            state: holiday.state ? holiday.state.split(',') : [],
                        }

                        if( typeof holidays[date] === 'undefined' ){
                            holidays[date] = [holidayData];
                        }else{                            
                            holidays[date].push(holidayData);
                        }
                    })
                    // console.log('holiday', holidays)
                    dispatch(setHoliday(holidays));
                },
            });
        }
    },[]);

    const resetGallery = () => {
        setViewGallery(false);
    }

    const resetVideos = () => {
        setViewVideos(false);
    }

    const resetVoices = () => {
        setViewVoices(false);
    }

    return (
        <div className="row">           
            <div className="col-12 calendar-header">
                <div className="row">
                    <div className="col-2">
                        <button className="btn-nav btn-prev" onClick={() => changeCalendar(-1)}><IconLeft /></button>
                    </div>
                    <div className="col-2">
                        
                    </div>
                    <div className="col-4">                        
                        <h1 className="page-title month-label">{monthLabel}</h1>
                        <span className="year-label">{yearLabel}</span>
                    </div>
                    <div className="col-1 p-0">
                        <select className="holiday-state" 
                            value={userState.calendar.holidayState}
                            onChange={(e) => dispatch(setHolidayState(e.target.value))} 
                        >
                            <option value="victoria">VIC</option>
                            <option value="nsw">NSW</option>
                            <option value="sa">SA</option>
                        </select>
                    </div>
                    <div className="col-1">
                        <IconLayer className={(userState.calendar.fullMode ? 'active ' : '' ) + 'calendar-toggle'} onClick={() => dispatch(setFullMode(!userState.calendar.fullMode))} />
                    </div>
                    <div className="col-2">
                        <button className="btn-nav btn-next" onClick={() => changeCalendar(1)}><IconRight /></button>
                    </div>
                </div>
            </div>

            <div className="col-12 calendar-wrap">
                
                <ul className={(userState.calendar.fullMode ? '' : 'd-none ' ) + 'platoon-selection'}>
                    <li className={(userState.calendar.legend.a === true ? 'active ' : '') + "platoon-a"} onClick={()=>dispatch(setCalendarLegends({key: 'a', val: !userState.calendar.legend.a}))}>A</li>
                    <li className={(userState.calendar.legend.b === true ? 'active ' : '') + "platoon-b"} onClick={()=>dispatch(setCalendarLegends({key: 'b', val: !userState.calendar.legend.b}))}>B</li>
                    <li className={(userState.calendar.legend.c === true ? 'active ' : '') + "platoon-c"} onClick={()=>dispatch(setCalendarLegends({key: 'c', val: !userState.calendar.legend.c}))}>C</li>
                    <li className={(userState.calendar.legend.d === true ? 'active ' : '') + "platoon-d"} onClick={()=>dispatch(setCalendarLegends({key: 'd', val: !userState.calendar.legend.d}))}>D</li>
                    {/* <li className={(userState.calendar.legend.day === true ? 'active ' : '') + "day"} onClick={()=>dispatch(setCalendarLegends({key: 'day', val: !userState.calendar.legend.day}))}>DAY</li> */}
                </ul>

                {/* {console.log(userState.calendar.month, 'month yes')} */}
                <CalendarMonth 
                    year={userState.calendar.year} 
                    month={userState.calendar.month} 
                    fullMode={userState.calendar.fullMode} 
                    activeLegend={{
                        a: userState.calendar.legend.a,
                        b: userState.calendar.legend.b,
                        c: userState.calendar.legend.c,
                        d: userState.calendar.legend.d,
                        day: userState.calendar.legend.day,
                    }}
                />
            </div>
            
            {( userState.calendar.dayLegends.dayPlatoon || userState.calendar.dayLegends.nightPlatoon || userState.calendar.dayLegends.isHoliday ) && 
            <div className="col-12 date-tag-wrap">
                <ul className="date-tag">
                    {userState.calendar.dayLegends.dayPlatoon !== "" &&<li className={'platoon-'+userState.calendar.dayLegends.dayPlatoon}>{userState.calendar.dayLegends.dayPlatoon.toUpperCase()} Platoon</li>}
                    {userState.calendar.dayLegends.nightPlatoon !== "" &&<li className={'platoon-'+userState.calendar.dayLegends.nightPlatoon}>{userState.calendar.dayLegends.nightPlatoon.toUpperCase()} Platoon <IconMoon /></li>}
                    {userState.calendar.dayLegends.isDayOt === true &&<li className={"ot "+ userState.user.Platoons[0]}>R/OT</li>}
                    {userState.calendar.dayLegends.isNightOt === true &&<li className={"ot "+ userState.user.Platoons[0]}>R/OT <IconMoon /></li>}
                    {userState.calendar.dayLegends.isDayOff === true &&<li className="dayoff">D/Off</li>}
                    {userState.calendar.dayLegends.isLeave === true &&<li className="leave">Leave</li>}
                    {userState.calendar.dayLegends.isHoliday === true &&<li className="holiday">Holiday</li>}
                </ul>
            </div>}
            
            <div className="col-12 events-wrap">
                {userState.calendar.holiday 
                && typeof userState.calendar.holiday[userState.calendar.activeDate] === 'undefined' 
                && 
                ( userState.calendar.dayEvents === null 
                    || typeof userState.calendar.dayEvents === 'undefined' 
                    || 
                    ( userState.calendar.dayEvents !== null 
                        && typeof userState.calendar.dayEvents !== 'undefined' 
                        && userState.calendar.dayEvents.length === 0
                    ) 
                ) && <span className="no-event">No event found</span>}

                <ul className="date-events">
                {userState.calendar.holiday && typeof userState.calendar.holiday[userState.calendar.activeDate] !== 'undefined' && userState.calendar.holiday[userState.calendar.activeDate].map((holiday, i)=>{
                    // console.log('holiday', holiday)
                    if(!holiday.state.length || holiday.state.includes(userState.calendar.holidayState))
                    return (
                        <li key={i} className="holiday">
                            <div className="event-top"><span className="bg"><span className="circle"></span> {holiday.event}</span></div>
                        </li>
                    )
                })}
                { ( userState.calendar.dayEvents != null && userState.calendar.dayEvents.length ) ?
                    <>
                    {userState.calendar.dayEvents.map((d) => {
                        let start_time = d.start_time;
                        let end_time = d.end_time; 
                        let time='';
                        let timeLabel;
                        
                        if(start_time && end_time){
                            time = `${start_time} - ${end_time}`;
                            timeLabel = time;
                        }else if(start_time){
                            time = start_time;
                            timeLabel = time;
                        }else{
                            timeLabel = d.date;
                        }

                        let tlabel = typeLabel(d.type);
                        let subject = `[UFUVIC] ${tlabel}: ${d.date} ${time}`;
                        let body = d.note;
                        // console.log('event',d)
                        return (
                            <li key={d.post_id} className={d.type}>
                                <div className="event-top"><span className="bg"><span className="circle"></span> {tlabel}</span> <span className="event-time">{timeLabel}</span> 
                                    
                                    {d.type === 'note' && <ul className="media-view">
                                        {/* {d.videos && <li><IconCamera /></li>} */}
                                        {d.photos.length > 0 && <li><IconPhoto onClick={()=>{setViewGallery(d)}}/></li>}
                                        {d.videos.length > 0 && <li><IconVideo onClick={()=>{setViewVideos(d)}}/></li>}
                                        {d.voices.length > 0 && <li><IconVoice onClick={()=>{setViewVoices(d)}}/></li>}
                                        {/* <li><IconAttachment /></li> */}
                                    </ul>}
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" className={'dropdown-toggle' + ' dropdown-' + d.post_id }>
                                            <IconDots />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={(e) => {dispatch(setEditEvent(d)); e.preventDefault();}}>Edit <IconEdit /></Dropdown.Item>
                                            <Dropdown.Item href={`mailto: ?subject=${subject}&body=${body}`}>Share <IconShare /></Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={(e => downloadIcs(d))}>Download ICS <IconIcs /></Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={(e) => {doDeleteEvent(d.post_id); e.preventDefault();}}>Delete <IconTrash /></Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="event-content">
                                    <h3 className="event-title">{d.title}</h3>
                                    <p className="event-desc">{d.note}</p>
                                </div>
                            </li>
                        )
                    })}
                </> : '' }
                </ul> 
            </div>
            
            <ImageGallery event={viewGallery} resetGallery={resetGallery} />
            <VideoGallery event={viewVideos} resetGallery={resetVideos} />
            <VoiceGallery event={viewVoices} resetGallery={resetVoices} />
            <AddEvent />
            <EditEvent event={userState.event.editEvent} />
        </div>
    )
}

export default Calendar;