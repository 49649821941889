import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import Alert from 'react-bootstrap/Alert';
import 'reactjs-popup/dist/index.css';
import {
    forgotUsername,
    resetForgotUsernameForm
} from '../slices/userSlice';
import { toast } from 'react-toastify';

const ForgotUsername = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const closeAddPopup = () => {
        props.setPopup(false);

        dispatch(resetForgotUsernameForm());
    }

    const getParams = () => {

        let params = {};
        
        if( firstName ) params.firstName = firstName;
        if( lastName ) params.lastName = lastName;
        if( email ) params.email = email;

        return params;
    }

    const handleSubmit = (el) => {

        el.preventDefault();

        let params = getParams();

        if( ! userState.forgotUsername.isLoading ){

            setValidated(true);
            
            const form = el.currentTarget;
            
            if (form.checkValidity() === false) {
                el.stopPropagation();
            }

            if(form.checkValidity() === true) {             
                console.log('params', params)
                dispatch(forgotUsername(params));
            }
        }
    }

    useEffect(()=>{
        if(userState.forgotUsername.success && ! userState.forgotUsername.isLoading){

            toast.success(() => 'Form submitted successfully.', {
                toastId: 'submit-forgot-username-success',
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    window.location.reload(false);
                }
            }); 
            
            dispatch(resetForgotUsernameForm());
        }
    }, [userState.forgotUsername.success])

    useEffect(() => {

        if( userState.forgotUsername.errorMsg ){
            dispatch(resetForgotUsernameForm());

            toast.error(userState.forgotUsername.errorMsg, {
                toastId: 'submit-forgot-username-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });            
        }
        
    }, [userState.forgotUsername.errorMsg])

    return (
        <>
        <Popup open={props.showPopup} position="center center" closeOnDocumentClick={false}>
            <div className="form-wrap">
                <h3>Forgot Login Email?</h3>
                <Form noValidate validated={validated} className="popup-form form-forgot-username col-12" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="firstName">
                        <Form.Control type="text" placeholder="First Name" required={true} value={firstName} onChange={(el)=>setFirstName(el.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter first name
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="lastName">
                        <Form.Control type="text" placeholder="Last Name" required={true} value={lastName} onChange={(el)=>setLastName(el.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter last name
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" placeholder="Contact Email" required={true} value={email} onChange={(el)=>setEmail(el.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter contact email
                        </Form.Control.Feedback>
                    </Form.Group>

                    {userState.forgotUsername.errorMsg && <Alert className="mt-3" variant="danger">{userState.forgotUsername.errorMsg}</Alert>}
                    
                    <a className="close-popup" onClick={()=> closeAddPopup()}>Cancel</a>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <small className="form-text text-muted">Submit this form to have the system administrator contact you for assistance</small>
                </Form>
            </div>
        </Popup>
        </>
    );
}

export default ForgotUsername;