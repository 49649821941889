import React from 'react';
import Map from './GoogleMap';

const Location = (props) => {

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Locator</h1>
                <Map />
            </div>
        </div>
    )
}

export default Location;