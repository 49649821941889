import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Popup from 'reactjs-popup';
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
import { ReactComponent as IconPlus } from '../icons/ico-plus.svg';
import { ReactComponent as IconPhoto } from '../icons/ico-photo.svg';
import { ReactComponent as IconVideo } from '../icons/ico-video.svg';
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconCamera } from '../icons/ico-camera.svg';
import { ReactComponent as IconAttachment } from '../icons/ico-attachment.svg';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import '../css/note.css';
import {
    updateNote
} from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { resetNoteSubmit, setEditNote } from '../slices/userSlice';

import VideoThumbnail from 'react-video-thumbnail';
import BrowserCamera from './BrowserCamera';
import VoiceRecorder from './VoiceRecorder';
import VoiceRecorder2 from './VoiceRecorder2';
import VoiceRecorder3 from './VoiceRecorder3';
import AudioRecorder from './AudioRecorder';
import { isBase64 } from '../helpers/generalFunctions';

const EditNote = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const noteObj = props.note;

    const [validated, setValidated] = useState(false);    
    const [editNotePopup, setEditNotePopup] = useState(noteObj!==null && noteObj.post_id);
    const [title, setTitle]  = useState(noteObj !== false ? noteObj.title : '');
    const [note, setNote] = useState(noteObj !== false ? noteObj.note : '');
    const [photos, setPhotos] = useState(noteObj !== false ? noteObj.edit_photos : []);
    const [videos, setVideos] = useState(noteObj !== false ? [...noteObj.edit_videos] : []);
    const [voices, setVoices] = useState(noteObj !== false ? noteObj.edit_voices : []);

    const [cameraOpen, setCameraOpen] = useState(false);
    const [voiceOpen, setVoiceOpen] = useState(false);
    const [fileStorage, setFileStorage] = useState([]);
    const uploadPhotoRef = useRef(null);
    const uploadVideoRef = useRef(null);

    const openEditNotePopup = () => {
        dispatch(resetNoteSubmit());
        dispatch(setEditNote());
    }

    const closeEditPopup = () => {
        setValidated(false);
        setTitle('');
        setNote('');

        dispatch(resetNoteSubmit());
    }

    const getParams = () => {

        let params = {
            post_id: props.note.post_id,
        };
        
        if( title ) params.title = title;
        if( note ) params.note = note;

        if(photos.length){
            if( typeof params.photos === 'undefined' ){
                params.photos = [];
            }
            photos.map((photo) => {
                params.photos.push(parseInt(photo.post_id));
            })
        }

        if(videos.length){
            if( typeof params.videos === 'undefined' ){
                params.videos = [];
            }
            videos.map((video) => {
                params.videos.push(parseInt(video.post_id));
            })
        }

        if(voices.length){
            if( typeof params.voices === 'undefined' ){
                params.voices = [];
            }
            voices.map((voice) => {
                params.voices.push(parseInt(voice.post_id));
            })
        }

        if (fileStorage) {
            fileStorage.map((file) => {
                if(file.type.match("image")){
                    if( typeof params.photos === 'undefined' ){
                        params.photos = [];
                    }
                    params.photos.push(file);
                }else if(file.type.match("video")){
                    if( typeof params.videos === 'undefined' ){
                        params.videos = [];
                    }
                    params.videos.push(file);
                }else if(file.type.match("audio")){
                    if( typeof params.voices === 'undefined' ){
                        params.voices = [];
                    }
                    params.voices.push(file);
                }
            });
        }

        return params;
    }

    const handleSubmit = (note) => {

        note.preventDefault();

        let params = getParams();

        if( ! userState.note.isEditLoading ){

            setValidated(true);
            
            const form = note.currentTarget;
            
            if (form.checkValidity() === false) {
                note.stopPropagation();
            }

            if(form.checkValidity() === true) {             
                // console.log('params', params)
                dispatch(updateNote(params));
            }
        }
    }

    useEffect(()=>{
        if(userState.note.editSuccess && ! userState.note.isEditLoading){

            toast.success(() => 'Note Edited', {
                toastId: 'edit-note-success',
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    window.location.reload(false);
                }
            }); 
            
            dispatch(resetNoteSubmit());
        }
    }, [userState.note.editSuccess])

    useEffect(() => {

        if( userState.note.editErrorMsg ){
            dispatch(resetNoteSubmit());

            toast.error(userState.note.editErrorMsg, {
                toastId: 'edit-note-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });            
        }
        
    }, [userState.note.editErrorMsg])

    const mediaUploadHandler = (event) => {
        if( typeof event.target.files[0] !== 'undefined'){
            // console.log(event.target.files[0]);
            setFileStorage([...fileStorage, ...[event.target.files[0]]]);
        }
    }

    const stopCamera = () => {
        setCameraOpen(false);
    }

    const stopRecording = () => {
        setVoiceOpen(false);
    }

    const addMediaToStorage = (file) => {
        setFileStorage([...fileStorage, ...[file]]);
    }

    const removeMedia = (index) => {
        let array = fileStorage; // make a separate copy of the array
        // console.log('index', index);
        // console.log('remove', array);
        array.splice(index, 1);
        setFileStorage([...array]);
    }

    const editNotePopupElement = () => {

        if(!editNotePopup){
            return (<></>);
        }
        
        return (
            <>
            {cameraOpen && <BrowserCamera stopCamera={stopCamera} addMediaToStorage={addMediaToStorage} />}
            {voiceOpen && <VoiceRecorder2 stopRecording={stopRecording} addMediaToStorage={addMediaToStorage} />}
            <Popup open={editNotePopup} position="center center" closeOnDocumentClick={false}>
                <div className="form-wrap">
                    <h3>Edit Note</h3>
                    <Form noValidate validated={validated} className="popup-form form-edit-note col-12" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="title">
                            <Form.Control type="text" placeholder="Title" required={true} value={title} onChange={(note)=>setTitle(note.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter title
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3 mb-5" controlId="note_note">
                            <div className="note-wrap">
                                <Form.Control as="textarea" rows={8} placeholder="Note" required={true} value={note} onChange={(note)=>setNote(note.target.value)} />
                                <ul className="action-wrap">
                                    <li><IconCamera onClick={() => setCameraOpen(true)} /></li>
                                    <li><IconVideo onClick={() => uploadVideoRef.current.click()} /><input style={{display:'none'}} ref={uploadVideoRef} type="file" accept="video/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconPhoto onClick={() => uploadPhotoRef.current.click()} /><input style={{display:'none'}} ref={uploadPhotoRef} type="file" accept="image/*" onChange={(event) => mediaUploadHandler(event)} /></li>
                                    <li><IconVoice onClick={() => setVoiceOpen(true)} /></li>
                                    {/* <li><IconAttachment /></li> */}
                                </ul>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                Please enter note
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="thumb-wrap">
                            <ul className='media-thumb'>
                                {/* {console.log('photos', photos)} */}
                                {photos && photos.map((photo, index) => {
                                    return <li key={index}>
                                        <img alt="not fount" src={photo.url} />
                                        <IconClose className="btn-remove" onClick={() => {
                                            let newArr = photos.filter((object, i) => {
                                                return i !== index;
                                            });
                                            
                                            setPhotos([...newArr])
                                        }} />
                                    </li>;  
                                })}
                                {videos && videos.map((video, index) => {
                                    let videoThumb = video.thumbBase64 !== '' ? video.thumbBase64 : require('../images/play-button.webp');
                                    return <li key={index}>
                                        <VideoThumbnail videoUrl={video.url} renderThumbnail={true} thumbnailHandler={(thumbnail) => {
                                            
                                            if(isBase64(thumbnail)) {
                                                let newArr = videos.filter((object, i) => {
                                                    return i !== index;
                                                });
                                                let clone = {...video};
                                                clone.thumbBase64 = thumbnail;
                                                newArr.splice(index, 0, clone);
                                                setVideos([...newArr])
                                             }
                                            // console.log('base64', thumbnail)
                                        }} />
                                        <img alt="not fount" src={videoThumb} />
                                        <IconClose className="btn-remove" onClick={() => {
                                            let newArr = videos.filter((object, i) => {
                                                return i !== index;
                                            });
                                            setVideos([...newArr])
                                        }} />
                                    </li>;  
                                })}
                                {voices && voices.map((voice, index) => {
                                    return <li key={index}>
                                        <img alt="not fount" src={require('../images/voice.jpg')} />
                                        <IconClose className="btn-remove" onClick={() => {
                                            let newArr = voices.filter((object, i) => {
                                                return i !== index;
                                            });
                                            setVoices([...newArr])
                                        }} />
                                    </li>;  
                                })}
                                {fileStorage && fileStorage.map((file, index) => {
                                    let url = URL.createObjectURL(file);

                                    if (file.type.match("video")) {
                                        let videoThumb = typeof file.thumbBase64 !== 'undefined' ? file.thumbBase64 : require('../images/play-button.webp');
                                        return <li key={index}>
                                            <VideoThumbnail videoUrl={url} renderThumbnail={true} thumbnailHandler={(thumbnail) => {

                                                if(isBase64(thumbnail)){
                                                    file.thumbBase64 = thumbnail;
                                                    setFileStorage([...fileStorage]);
                                                 }
                                            }} />
                                            <img alt="not fount" src={videoThumb} />                                            
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;                                
                                    } else if (file.type.match("image")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={url} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    } else if (file.type.match("audio")) {
                                        return <li key={index}>
                                            <img alt="not fount" src={require('../images/voice.jpg')} />
                                            <IconClose className="btn-remove" onClick={() => removeMedia(index)} />
                                        </li>;
                                    }
                                })}
                            </ul>
                        </div>

                        {userState.note.editErrorMsg && <Alert className="mt-3" variant="danger">{userState.note.editErrorMsg}</Alert>}
                        
                        <a className="close-popup" onClick={()=> closeEditPopup()}>Cancel</a>

                        <Button variant="primary" type="submit">
                            Update Note
                        </Button>
                    </Form>
                </div>
            </Popup>
            </>
        );
    }

    return (
        <>
            <LoadingOverlay
                active={userState.note.isEditLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
                >                    
            </LoadingOverlay>

            {editNotePopupElement()}
        </>
    )
}

export default EditNote;