import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
// import '../css/calendar.css';
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel-autoheight';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const ImageGallery = (props) => {

    const [eventObj, setEventObj] = useState(props.event);

    const [ImageGalleryPopup, setImageGalleryPopup] = useState(eventObj!==false && eventObj.post_id);

    const [photoIndex, setPhotoIndex] = useState(0);

    const closeViewPopup = () => {
        setImageGalleryPopup(false);  
        setPhotoIndex(0);      
        props.resetGallery();
    }

    const ImageGalleryPopupElement = () => {
        
        if(!ImageGalleryPopup){
            return (<></>);
        }
        
        return (
            <Popup className="view-gallery" open={ImageGalleryPopup} position="center center" closeOnDocumentClick={false}>                
                <a className="close-popup btn-close" onClick={()=> closeViewPopup()}></a>
                <div className="form-wrap">
                    <h3>Photos</h3>                    
                    <div className="view-media text-center">
                    <OwlCarousel className='owl-theme' loop={false} margin={10} items={1} nav={true} dots={false} center={true} autoHeight={false} lazyLoad={true} onInitialized={(owl) => {
                        // console.log('owl', owl);
                        // $(owl.relatedTarget.$element[0]).trigger('next.owl.carousel');; // refresh to fix height
                        // owl.relatedTarget.prev();
                    }}>
                        {eventObj.photos.map((photo, index) => { 
                            return <div className='item' key={index}>
                                <img src={photo} />
                            </div>
                        })}
                    </OwlCarousel>
                    </div>
                </div>
            </Popup>
        );
    }

    const ImageGalleryLightboxElement = () => {
        
        if(!ImageGalleryPopup){
            return (<></>);
        }

        let images = eventObj.photos;
        // console.log('images', images)
        
        return <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => closeViewPopup() }
                    onMovePrevRequest={() => {
                        setPhotoIndex((photoIndex + images.length - 1) % images.length);
                    }}
                    onMoveNextRequest={() => {
                        setPhotoIndex((photoIndex + 1) % images.length);
                    }}
                />
    }

    useEffect( () => {
        setEventObj(props.event);
        setImageGalleryPopup(props.event!==false && props.event.post_id);
    }, [props.event])

    // useEffect( () => {
    //     setEventObj(props.event);
    // }, [eventObj])

    return (
        <>
            {ImageGalleryLightboxElement()}
        </>
    )
}

export default ImageGallery;