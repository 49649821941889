import { formatDate2 } from './time';
import md5 from 'md5';

const ics = require('ics');

const event = {
  start: [2018, 5, 30, 6, 30],
  duration: { hours: 6, minutes: 30 },
  title: 'Bolder Boulder',
  description: 'Annual 10-kilometer run in Boulder, Colorado',
  location: 'Folsom Field, University of Colorado (finish line)',
  url: 'http://www.bolderboulder.com/',
  geo: { lat: 40.0095, lon: 105.2669 },
  categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
  status: 'CONFIRMED',
  busyStatus: 'BUSY',
  organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
  attendees: [
    { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
    { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
  ]
}

const proccess_download = (event) => {
    ics.createEvent(event, (error, value) => {
      if (error) {
          console.log(error)
          return
      }
      // writeFileSync(`${__dirname}/event.ics`, value)

      export_file(event.title, value)
      console.log(value)
      // BEGIN:VCALENDAR
      // VERSION:2.0
      // CALSCALE:GREGORIAN
      // PRODID:adamgibbons/ics
      // METHOD:PUBLISH
      // X-PUBLISHED-TTL:PT1H
      // BEGIN:VEVENT
      // UID:S8h0Vj7mTB74p9vt5pQzJ
      // SUMMARY:Bolder Boulder
      // DTSTAMP:20181017T204900Z
      // DTSTART:20180530T043000Z
      // DESCRIPTION:Annual 10-kilometer run in Boulder\, Colorado
      // X-MICROSOFT-CDO-BUSYSTATUS:BUSY
      // URL:http://www.bolderboulder.com/
      // GEO:40.0095;105.2669
      // LOCATION:Folsom Field, University of Colorado (finish line)
      // STATUS:CONFIRMED
      // CATEGORIES:10k races,Memorial Day Weekend,Boulder CO
      // ORGANIZER;CN=Admin:mailto:Race@BolderBOULDER.com
      // ATTENDEE;RSVP=TRUE;ROLE=REQ-PARTICIPANT;PARTSTAT=ACCEPTED;CN=Adam Gibbons:mailto:adam@example.com
      // ATTENDEE;RSVP=FALSE;ROLE=OPT-PARTICIPANT;DIR=https://linkedin.com/in/brittanyseaton;CN=Brittany
      //   Seaton:mailto:brittany@example2.org
      // DURATION:PT6H30M
      // END:VEVENT
      // END:VCALENDAR
    })
}

const export_file = (filename, text) => {
    const fileData = text;
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = filename + ".ics";
    link.href = url;
    link.click();
}

export const downloadIcs = (event) => {

  // console.log('event', event)
  var id = 'ufuvic.mobi_' + event.post_id;
  var uid = md5(id);

  var d = new Date(event.date),
        month = parseInt(d.getMonth()) + 1,
        day = d.getDate(),
        year = d.getFullYear();

  var start = [];

  start.push(year);
  start.push(month);
  start.push(day);

  if(event.start_time){
    var t = new Date(event.date+' '+event.start_time),
        minutes = t.getMinutes(),
        hour = t.getHours();
    
    start.push(hour);
    start.push(minutes);
  }

  var end = [];

  end.push(year);
  end.push(month);
  end.push(day);

  if(event.end_time){
    var t = new Date(event.date+' '+event.end_time),
        minutes = t.getMinutes(),
        hour = t.getHours();
    
    end.push(hour);
    end.push(minutes);
  }

  var params = {
    uid: uid,
    start: start,
    end: end,
    title: event.title,
    description: event.note,
    sequence: parseInt(event.sequence),
  }

  if( event.is_recurring ){
			
    var recc = [];

    if( event.period ){
      
      switch( event.period ){
        case 'week': recc['FREQ'] = 'WEEKLY'; break;
        case 'month': recc['FREQ'] = 'MONTHLY'; break;
        case 'year': recc['FREQ'] = 'YEARLY'; break;
        case 'day':
        default:
           recc['FREQ'] = 'DAILY';
           break;					
      }
    }
    if( event.repeat_number ){
      recc['INTERVAL'] = event.repeat_number;
    }

    if( event.end_type ){
      switch( event.end_type ){
        case "never": break;
        case "on": if( event.end_date ) recc['UNTIL']  = formatDate2( event.end_date ); break;
        case "after": if( event.occurences ) recc['COUNT'] = event.occurences; break;					
      }
    }

    // console.log('recc',recc)
    
    var toImplode = [];
    Object.keys(recc).forEach( (key) => {
      let val = recc[key];
      toImplode.push(`${key}=${val}`);
    })
    
    // console.log('toImplode',toImplode)

    params.recurrenceRule = toImplode.join(';');
  }

  // console.log(params);

  proccess_download(params);
}