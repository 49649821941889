import React from 'react';
import Iframe from 'react-iframe'
// import LoadingOverlay from 'react-loading-overlay-ts';

const UFUEvent = (props) => {

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">UFU Upcoming Events</h1>

                <Iframe 
                    url="https://ufuvic.com.au/widget/ufuvic-events" 
                    width="100%" 
                    height="100%" 
                    allow="geolocation; microphone; camera; midi; encrypted-media"
                    loading={'loading...'}
                    // styles={{height: "100vh"}}
                />
            </div>
        </div>
    )
}

export default UFUEvent;