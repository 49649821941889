import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconSpeaker } from '../icons/ico-speaker.svg';
import { ReactComponent as IconCheck } from '../icons/ico-check.svg';
import { get_bulletins } from '../helpers/api.service';
import { Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { notificationSeen } from '../slices/userSlice';

const Bulletin = (props) => {

    const userState = useSelector(state => state.user);

    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const [isSearching, setIsSearching] = useState(0);
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const loadData = () => {
        setIsSearching(1);
        get_bulletins(search, userState.credentials.refresh_token).then(res => {
            if (res.data.data.bulletins != null) {
                setIsSearching(0);
                setData(res.data.data.bulletins);
                setTimeout(() => {
                    window.scrollTo({
                        top: 1, 
                        behavior: 'auto'
                        /* you can also use 'auto' behaviour
                           in place of 'smooth' */
                    });
                    console.log('scroll')
                 }, 1);
               
            }
        }).catch((err) => {
            setIsSearching(0);
            setError(err.message);
        });
    }

    useEffect(() => {
        loadData();
        console.log('trigger')
    }, [search])

    return (
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Bulletins</h1>

                <div className="search-wrap search-bulletin">
                    <Form onSubmit={(event) => event.preventDefault()}>
                        <Form.Group className="mb-3">
                            <IconSearch />
                            <Form.Control type="text" placeholder="" value={search} onChange={(event) => setSearch(event.target.value)} />
                        </Form.Group>
                    </Form>
                </div>

                <div className="btn-action-wrap">
                    <Form.Group className="mt-3">
                        <Button className="btn-clear-notification" variant="secondary" onClick={() => {
                            dispatch(notificationSeen({for:'all', type:'bulletin'})).then(() => {
                                loadData();
                            });
                        }}><IconCheck /> Mark all as read</Button>
                    </Form.Group>
                </div>

                <div className="item-list list-bulletin">
                    {isSearching === 1 && <p className="text-center">Loading...</p>}
                    {isSearching === 0 && Object.entries(data).map((obj, index) => {
                        let bulletin = obj[1];
                        // console.log('bulletin',bulletin)
                        return (
                            <li key={index}>
                                {/* <div className="item-header highlight"><span className="item-icon highlight"><IconSpeaker /></span><a target="_blank" href={bulletin.url}>{bulletin.title}</a></div> */}
                                <div className={'item-header' + (bulletin.notif_seen === "0" || bulletin.notif_seen === 0 ? ' highlight' : '')}><span className={'item-icon' + ( bulletin.notif_seen === "0" || bulletin.notif_seen === 0 ? ' highlight' : '' )}><IconSpeaker /></span><Link onClick={()=>{
                                    if( bulletin.notif_seen === "0" || bulletin.notif_seen === 0 ){
                                        dispatch(notificationSeen({id: bulletin.notif_id, type:'bulletin'}));
                                    }
                                }} to={'/pdf/' + btoa(bulletin.url)}>{bulletin.title}</Link></div>
                                <div className="item-desc">{bulletin.desc}</div>
                            </li>)
                    })}
                    {isSearching === 0 && data.length === 0 && <p className="text-center">Not found</p>}
                    {isSearching === 0 && typeof data.length === 'undefined' && error === '' && <p className="text-center">Bulletin not available</p>}
                    {isSearching === 0 && error !== '' && <Alert className="mt-3" variant="warning">{error}</Alert>}
                </div>
            </div>
        </div>
    )
}

export default Bulletin;