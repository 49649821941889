import { getDifferenceInDays } from "./time";

export const getFieldValue = (user, name) => {
    let val = '';

    if( typeof user.FieldValues !== 'undefined' && user.FieldValues !==null ){
        user.FieldValues.forEach(element => {
             if(element.FieldName == name){
                val = element.Value;
                if(element.Value===null){
                    val = '';
                }else if(typeof element.Value === 'object'){
                    val = element.Value.Label;
                }else{
                    val = element.Value;
                }
                return;
            }
        });
    }
    
    return val;
}

export const isProfileComplete = (user) => {

    let complete = true;

    if(!user.FirstName){
        complete = false;
    }
    if(!user.LastName){
        complete = false;
    }
    if(!getFieldValue(user,'Rank')){
        complete = false;
    }

    return complete;
}

export const profileCompletionReminder = (user) =>{
    let reminder = false;

    if( typeof user.profileReminder === 'undefined' ){
        reminder = true;
    } else if( user.profileReminder === '' ){
        reminder = true;
    } else if( getDifferenceInDays(new Date(user.profileReminder), new Date()) <= 0 ){
        reminder = true;
    }

    // if( getDifferenceInDays(new Date(), new Date('2022-11-16')) <= 0 ){
        
    //     console.log('diff ', getDifferenceInDays(new Date(), new Date('2022-11-16')));
    // }
    // console.log('diff ', getDifferenceInDays(new Date(user.profileReminder), new Date()));

    return reminder;
}

export const isBase64 = (fullstr) => {
    console.log('typeof', typeof fullstr);
    console.log('base64', fullstr);

    try{
        let arr = fullstr.split(',');

        if(typeof arr[1] === 'undefinded'){
            return false;
        }

        let str = arr[1];
        
        return str.length % 4 == 0 && /^[A-Za-z0-9+/]+[=]{0,3}$/.test(str);
    } catch (err){
        return false;
    }
    // if (str ==='' || str.trim() ===''){ return false; }
    // try {
    //     return btoa(atob(str)) == str;
    // } catch (err) {
    //     console.log('error', err);
    //     return false;
    // }
}