import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Papa from 'papaparse';
import { get_contacts } from '../helpers/api.service';
import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconPerson } from '../icons/ico-person.svg';
import { ReactComponent as IconPhone } from '../icons/ico-phone.svg';
import { ReactComponent as IconMail } from '../icons/ico-mail.svg';
import '../css/contact.css';

const Contact = (props) => {

    const [catBCOM, setCatBCOM] = useState(false);
    const [catDelegate, setCatDelegate] = useState(false);
    const [catHSR, setCatHSR] = useState(false);
    const [catOffice, setCatOffice] = useState(false);
    const [catCommittees, setCatCommittees] = useState(false);

    const bcomFile = require('../csv/bcom.csv');
    const delegateFile = require('../csv/delegates.csv');
    const hsrFile = require('../csv/hsr.csv');
    const officeFile = require('../csv/office.csv');
    // const [data, setData] = useState([]);
    const [dataBcom, setDataBcom] = useState([]);
    const [dataDelegate, setDataDelegate] = useState([]);
    const [dataHSR, setDataHSR] = useState([]);
    const [dataOffice, setDataOffice] = useState([]);
    const [dataCommittees, setDataCommittees] = useState([]);

    const [search, setSearch] = useState('');

    const loadData = () => {
        get_contacts().then(res => {
            if (res.data.data.contacts != null) {
                let all = res.data.data.contacts;
                let bcom = [];
                let delegates = [];
                let office = [];
                let hsr = [];
                let committees = [];

                all.map((contact) => {
                    
                    switch(contact.Category){
                        case "bcom":
                                bcom.push({
                                    type: 'bcom',
                                    title: contact.Position,
                                    name:  contact.Fullname,
                                    email: contact.Email,
                                    phone: contact.Phone,
                                })  
                            break;
                        case "delegates":
                                delegates.push({
                                    type: 'delegates',
                                    title: contact.Position,
                                    name:  contact.Fullname,
                                    email: contact.Email,
                                    phone: contact.Phone,
                                })  
                            break;
                        case "hsr":
                                hsr.push({
                                    type: 'hsr',
                                    title: contact.Position,
                                    name:  contact.Fullname,
                                    email: contact.Email,
                                    phone: contact.Phone,
                                })  
                            break;
                        case "office":
                                office.push({
                                    type: 'office',
                                    title: contact.Position,
                                    name:  contact.Fullname,
                                    email: contact.Email,
                                    phone: contact.Phone,
                                })  
                            break;
                        case "committees":
                                committees.push({
                                    type: 'committees',
                                    title: contact.Position,
                                    name:  contact.Fullname,
                                    email: contact.Email,
                                    phone: contact.Phone,
                                })  
                            break;
                        default: break;
                    }
                })

                setDataOffice(office);
                setDataBcom(bcom);
                setDataDelegate(delegates);
                setDataHSR(hsr);
                setDataCommittees(committees);
            }
        }).catch((err) => {
            console.log('error', err.message);
        });
    }

    useEffect(() => {
        loadData();
    }, [])

    const handleToggleBCOM = () => {
        setCatBCOM(!catBCOM);
    }

    const handleToggleDelegate = () => {
        setCatDelegate(!catDelegate);
    }

    const handleToggleHSR = () => {
        setCatHSR(!catHSR);
    }

    const handleToggleOffice = () => {
        setCatOffice(!catOffice);
    }

    const handleToggleCommittees = () => {
        setCatCommittees(!catCommittees);
    }
    // console.log(data);

    let data = [...dataBcom, ...dataDelegate, ...dataHSR, ...dataOffice, ...dataCommittees];

    let filteredData = data.filter((contact) => {

        let isSearch = search!=='' ? true : false;
        let keyFound = contact.name && contact.name.toLowerCase().includes(search.toLowerCase()) || 
                       contact.title && contact.title.toLowerCase().includes(search.toLowerCase()) ||
                       contact.email && contact.email.toLowerCase().includes(search.toLowerCase()) ||
                       contact.phone && contact.phone.toLowerCase().includes(search.toLowerCase());
        let searchQuery = ( isSearch && keyFound || ! isSearch);

        if(! catBCOM && ! catDelegate && ! catHSR && ! catOffice && ! catCommittees ){
            return 0;
        }
        // if(! catBCOM && ! catDelegate && ! catHSR && ! catOffice && searchQuery ){
        //     return contact;
        // }

        if( catBCOM && contact.type=== 'bcom' && searchQuery ){
            return contact;
        }

        if( catDelegate && contact.type=== 'delegates' && searchQuery ){
            return contact;
        }

        if( catHSR && contact.type=== 'hsr' && searchQuery ){
            return contact;
        }
        if( catOffice && contact.type=== 'office' && searchQuery ){
            return contact;
        }
        if( catCommittees && contact.type=== 'committees' && searchQuery ){
            return contact;
        }

        return 0;
    })

    return (
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Contacts</h1>

                <div className="search-wrap search-contact">
                    <Form onSubmit={(event)=> event.preventDefault()}>
                        <Form.Group className="mb-3">
                            <IconSearch />
                            <Form.Control type="text" placeholder="" value={search} onChange={(event) => {
                                setSearch(event.target.value);
                            }} />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <ul className="contact-category">
                                <li className={catBCOM ? 'active' : ''} onClick={() => handleToggleBCOM()}>BCOM</li>
                                <li className={catDelegate ? 'active' : ''} onClick={() => handleToggleDelegate()}>Delegates</li>
                                <li className={catHSR ? 'active' : ''} onClick={() => handleToggleHSR()}>HSR</li>
                                <li className={catOffice ? 'active' : ''} onClick={() => handleToggleOffice()}>Office</li>
                                <li className={catCommittees ? 'active' : ''} onClick={() => handleToggleCommittees()}>Committees</li>
                            </ul>
                        </Form.Group>
                        <div className="result-count">{filteredData.length == 1 ? '1 result' : (typeof filteredData.length !== 'undefined' ? filteredData.length : '0') + ' results' }</div>
                    </Form>
                </div>

                <div className="item-list list-contact">
                    {filteredData.map((contact, index) => {
                    return (
                        <li key={index}>
                            <div className="item-header">
                                <span className="item-icon highlight"><IconPerson /></span>{contact.name} {contact.title!=='' && <span className="position">{contact.title}</span>}
                            </div>
                            <div className="item-desc">
                                {contact.phone !== '' && <label><a href={`tel:${contact.phone}`}><IconPhone /> {contact.phone}</a></label>}
                                {contact.email !== '' && <label><a href={`mailto:${contact.email}`}><IconMail /> {contact.email}</a></label>}
                            </div>
                        </li>
                    )
                    })}
                    {! catBCOM && ! catDelegate && ! catHSR && ! catOffice && ! catCommittees && search === '' && <p className="text-center">Please select category</p>}
                    {filteredData.length === 0 && search !== '' && <p className="text-center">Not found</p>}
                </div>
            </div>
        </div>
    )
}

export default Contact;