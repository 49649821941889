import React from 'react';
import Iframe from 'react-iframe';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../helpers/constants';

const PdfViewer = (props) => {

    const { urlBase64 } = useParams();
    const pdf = atob(urlBase64);

    const embedPdfUrl = (url) => {
        return BASE_URL + 'wp-content/plugins/wonderplugin-pdf-embed/pdfjs/web/viewer.html?disabletext=0&disabledoc=1&disableopenfile=1&file=' + url;
    }

    return (
        <div className="row">           
            <div className="col-12">
                <Iframe 
                    url={embedPdfUrl(pdf)} 
                    width="100%" 
                    height="100%" 
                    allowFullScreen={true}
                    // styles={{height: "100vh"}}
                />
            </div>
        </div>
    )
}

export default PdfViewer;