import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import '../css/login.css';
import { ReactComponent as IconArrow } from '../icons/ico-right-arrow.svg';
// import { ReactComponent as Logo } from '../images/UFUA-Logo.svg';
import {
    login,
} from '../slices/userSlice';
import { Navigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import ForgotUsername from './ForgotUsername';
// import BounceLoader from 'react-spinners/BounceLoader';
// import { resetLoginError } from '../slices/userSlice';

const Login = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();    

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);

    const [showForgotUsername, setShowForgotUsername] = useState(false);

    const emailHandler = (event) => {
        const email = event.target.value;
        setEmail( email );
    }

    const passHandler = (event) => {
        const password = event.target.value;
        setPassword( password );
    }

    const handleLogin = (event) => {
        
        event.preventDefault();

        if( ! userState.isLoginLoading ){
            
            const form = event.currentTarget;
            
            if (form.checkValidity() === false) {
            event.stopPropagation();
            }

            setValidated(true);

            if(form.checkValidity() === true) {
                dispatch(login({                 
                    email,
                    password, 
                }));
            }
        }
    }

    if( userState.isLoggedIn ) {
        return <Navigate to="/dashboard" />
    }

    return (
        <div className="login-wrap row">
            <LoadingOverlay
                active={userState.isLoginLoading}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
                >                    
            </LoadingOverlay>
            <div className="logo-wrap col-12">
                {/* <Logo /> */}
                <LazyLoad>
                    <img id="logo" src={require('../images/logo2.png')} alt="UFUVIC" />
                </LazyLoad>
                <h1 className="login-title">Welcome</h1>
                <p className="login-message">Sign into UFUVIC</p>
            </div>
            <Form noValidate validated={validated} className="form-login col-12" onSubmit={handleLogin}>
                <Form.Group className="mb-2" controlId="login_email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" required={true} onChange={emailHandler} />
                    <Form.Control.Feedback type="invalid">
                        Please enter valid email
                    </Form.Control.Feedback>
                </Form.Group>

                <a className="forgot-pass" onClick={() => {
                    setShowForgotUsername(true);
                }}>Forgot login email?</a>

                <Form.Group className="mb-2" controlId="login_pass">
                    <Form.Label>Enter Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter Password" required={true} onChange={passHandler} />
                    <Form.Control.Feedback type="invalid">
                        Please enter password
                    </Form.Control.Feedback>
                </Form.Group>

                <a className="forgot-pass" target="_blank" rel="noreferrer" href="https://www.ufuvic.com.au/Sys/ResetPasswordRequest">Forgot Password?</a>
                {/* <Link className="forgot-pass" to={`/external/?title=Forgot Password&url=` + btoa('https://www.ufuvic.com.au/Sys/ResetPasswordRequest')}>Forgot Password?</Link> */}

                {userState.loginErrorMsg && <Alert className="mt-3" variant="danger">{userState.loginErrorMsg}</Alert>}

                <Button className="btn-login btn-w-icon" variant="primary" type="submit">
                    Log In
                    <IconArrow className="svg-ico" />
                </Button>
            </Form>

            <ForgotUsername showPopup={showForgotUsername} setPopup={setShowForgotUsername} />
        </div>
    );
}

export default Login;