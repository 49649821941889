import { PUSH_NOTIFICATION_PUBLIC_KEY as publicKey } from './helpers/constants';
import { getMobileOS } from './helpers/browser';

export default function swDev() {
    if('serviceWorker' in navigator){
        let swUrl= `${process.env.PUBLIC_URL}/sw.js`;
        // console.log('swUrl', swUrl);
        navigator.serviceWorker.register(swUrl).then((response) => {

            if(getMobileOS() !== 'iOS'){
                return response.pushManager.getSubscription().then( (subscription) => {
                    return response.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: publicKey
                    }).then( (subscribe) => {           
                        let json = JSON.stringify(subscribe); 

                        localStorage.setItem("pushnotification", json);
                        console.log('pushNotification', json);
                    });
                })
            }
            // console.log('serviceWorker response: ', response);
        }).catch((error) => {
            console.error('Service worker register error:', error);
        })
    }else{
        console.warn('service worker does not enable in this browser')
    }
}