import React from 'react';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { FACING_MODES } from 'react-html5-camera-photo';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';

const BrowserCamera = (props) => {
  function handleTakePhoto (dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto', dataUri);
    let file = dataURLtoFile( dataUri, 'photo-' + Date.now() + '.png')
    // const myFile = new File([myBlob], 'image.jpeg', {
    //     type: myBlob.type,
    // });

    // console.log('image', file);
    props.addMediaToStorage(file);
    props.stopCamera();
  }

  function dataURLtoFile(dataurl, filename) {
  
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
  }

  return (
    <div className="camera-wrap">
      <Camera
        onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
        idealFacingMode = {FACING_MODES.ENVIRONMENT}
        isDisplayStartCameraError={true}
      />
      <IconClose className="act-close" onClick={() => props.stopCamera()} />
    </div>
  );
}

export default BrowserCamera;