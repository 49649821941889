import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker, StandaloneSearchBox } from "@react-google-maps/api";
// import { LoadScript} from "@react-google-maps/api";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import { ReactComponent as IconSearch } from '../icons/ico-search.svg';
import { ReactComponent as IconAtsign } from '../icons/ico-atsign.svg';
import { ReactComponent as IconSmartphone } from '../icons/ico-smartphone.svg';
import { ReactComponent as IconTarget } from '../icons/ico-target.svg';
import { ReactComponent as IconDirection } from '../icons/ico-direction.svg';
import { get_locations } from "../helpers/api.service";
import LazyLoad from "react-lazy-load";

const Map = () => {

    const [currLat, setCurrLat] = useState('');
    const [currLng, setCurrLng] = useState('');

    const [markers, setMarkers] = useState(null);

    const [selectedOption, setSelectedOption] = useState(null);
    const [locationOptions, setLocationOptions] = useState([]);

    useEffect(() => {
        get_locations().then(res => {
            if(res.data.data.locations!=null){
                setMarkers(res.data.data.locations);

                let options=[];
                options = res.data.data.locations.map((marker) => {
                    let coor = marker.position;
                    return {
                        label: marker.address + (marker.number ? ` (FS ${marker.number})` : ''),
                        value: coor,
                    }
                })

                // console.log('optons', options)

                setLocationOptions(options);
            }
        });
    },[])

    if (navigator.geolocation) {    
        navigator.geolocation.watchPosition((position) => {
            setCurrLat(position.coords.latitude);
            setCurrLng(position.coords.longitude);
        });      
    }else{
        setCurrLat('');
        setCurrLng('');
    }

    const [activeMarker, setActiveMarker] = useState(null);
    const [center, setCenter] = useState({
        lat: -37.020100,
        lng: 144.964600,
    });
    const [zoom, setZoom] = useState(7);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        if (typeof google !== 'undefined') {
            // const bounds = new google.maps.LatLngBounds();
            // markers.forEach(({ position }) => bounds.extend(position));
            // map.fitBounds(bounds);
        }
    };

    const [searchBox, setSearchBox] = useState(null);

    const onLoad = ref => {
        setSearchBox(ref);
    }

    const onPlacesChanged = () => {
        let place = searchBox.getPlaces();
        // console.log(place);
        let lat = place[0].geometry.location.lat();
        let lng = place[0].geometry.location.lng();

        setZoom(15);
        setCenter({
            lat,
            lng
        })
    }

    const customStyles = {
        // option: (provided, state) => ({
        //     ...provided,
        //     borderBottom: '1px dotted pink',
        //     color: state.isSelected ? 'red' : 'blue',
        //     padding: 20,
        // }),
        control: (styles) => {
            // console.log('control styles', styles);
            return ({
                ...styles,
                '& svg': {
                    display: 'none',
                },
                '&:hover': {
                    // color: '#212529',
                    // backgroundColor: '#fff',
                    borderColor: '#86b7fe',
                    outline: '0',
                    boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%)',
                },
                display: 'block',
                boxSizing: 'border-box',
                width: '100%',
                height: '51px',
                background: '#FFFFFF',
                border: '1px solid #DCDCDC',
                borderRadius: '8px',
                paddingLeft: '30px',
                borderColor: 'none',
            })
        },
        input: (styles) => {
            // console.log('input styles', styles);
            return ({
                ...styles,
                height: '43px',
            })
        },
        singleValue: (styles) => {
            // console.log('single styles', styles);
            return ({
                ...styles,
                // height: '43px',
                marginTop:'10px',
            })
        },
    }

    if( markers === null || locationOptions.length === 0){
        return <>Loading...</>
    }

    return (
        <>
            {typeof google !== 'undefined' ?
            <>
            {/* <LoadScript
                googleMapsApiKey="AIzaSyDuQ5zA1N7-IcAJnbMm_QoZLCNmRhFilNw"
                libraries={["places"]}
            >                 */}
                <div className="search-wrap search-location">
                    <Form.Group className="mb-3">
                        <IconSearch />
                        <Select
                            styles={customStyles}
                            defaultValue={selectedOption}
                            onChange={(option) => {
                                setSelectedOption(option);
                                setZoom(15);
                                setCenter(option.value);
                            }}
                            placeholder="FS 2"
                            options={locationOptions}
                        />                        
                        {/* <StandaloneSearchBox
                            onLoad={onLoad}
                            onPlacesChanged={
                                onPlacesChanged
                            }
                        >
                            <Form.Control type="text" placeholder="" />
                                    
                        </StandaloneSearchBox> */}
                    </Form.Group>
                </div>

                
                        
                {currLat!== '' && currLng !== '' &&
                <p className="set-curr-location" onClick={() => {
                    setZoom(15);
                    setCenter({
                        lat: currLat,
                        lng: currLng
                    })
                }}><IconTarget /> Near me</p>}
                {/* <input
                    className="map-input-search"
                    type="text"
                    placeholder="Customized your placeholder"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                /> */}

                <div className="google-map-wrap">
                    <LazyLoad>
                    <GoogleMap
                        className="google-map"
                        center={center}
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{ width: "100%", height: "400px" }}
                        zoom={zoom}
                        onZoomChanged={() => {}}
                    >
                        {markers!== null && markers.map(({ name, position, address, number, email, phone }, index ) => {
                        
                        let direct = `https://www.google.com/maps/dir/${currLat},${currLng}/${position.lat},${position.lng}`;
                        return (
                            <Marker
                                key={index}
                                position={position}
                                onClick={() => handleActiveMarker(index)}
                            >
                                {activeMarker === index ? (
                                    <InfoWindow className="location-info-wrap" onCloseClick={() => setActiveMarker(null)}>
                                        <div className="location-info">
                                            <h4 className="loc-title">{name}</h4>
                                            <span className="loc-number">{`Fire Station ${number}`}</span>
                                            <span className="loc-address">{address}</span>
                                            {email!== '' && typeof email!== 'undefined' && <span className="loc-email"><IconAtsign width={15} height={15} />&nbsp;<a href={'mailto:'+email}>{email}</a></span>}
                                            {phone!== '' && typeof phone!== 'undefined' && <span className="loc-phone"><IconSmartphone width={15} height={15} />&nbsp;<a href={'tel:'+phone}>{phone}</a></span>}
                                            {currLat!=='' && currLng!=='' && <span className="loc-direction"><IconDirection width={15} height={15} />&nbsp;<a target="_blank" href={direct}>Direction</a></span>}
                                        </div>
                                    </InfoWindow>
                                ) : null}
                            </Marker>
                        )})}
                    </GoogleMap>
                    </LazyLoad>
                </div>
            {/* </LoadScript> */}
            </>
            : <Alert className="mt-3" variant="warning">Map not available in offline mode</Alert>}
        </>
    );
}

export default Map;
