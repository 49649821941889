import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
// import '../css/calendar.css';
// import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactPlayer from 'react-player'
import LazyLoad from 'react-lazy-load';

const VideoGallery = (props) => {

    const [eventObj, setEventObj] = useState(props.event);

    const [VideoGalleryPopup, setVideoGalleryPopup] = useState(eventObj!==false && eventObj.post_id);

    const closeViewPopup = () => {
        setVideoGalleryPopup(false);        
        props.resetGallery();
    }

    const VideoGalleryPopupElement = () => {
        
        if(!VideoGalleryPopup){
            return (<></>);
        }
        
        return (
            <Popup className="view-gallery" open={VideoGalleryPopup} position="center center" closeOnDocumentClick={false}>                
                <a className="close-popup btn-close" onClick={()=> closeViewPopup()}></a>
                <div className="form-wrap">
                    <h3>Videos</h3>                    
                    <div className="view-media text-center">
                    <OwlCarousel className='owl-theme' loop={false} margin={10} items={1} nav={true} dots={false} autoWidth={false}>
                        {eventObj.videos.map((video, index) => { 
                            return <div className='item' key={index}>
                                <video 
                                    width="100%"
                                    height="100%" 
                                    controls={true} 
                                    autoPlay={index === 0 ? true : false}
                                    muted={true}
                                    playsInline={true}
                                    crossOrigin="anonymous"
                                >
                                    
                                    <source src={video} />
                                    Your browser does not support the video tag.
                                </video>
                                {/* <ReactPlayer 
                                    url={video}
                                    height='100%'
                                    width='100%'
                                    controls={true}
                                    playing={index === 0 ? true : false}
                                /> */}
                            </div>
                        })}
                    </OwlCarousel>
                    </div>
                </div>
            </Popup>
        );
    }

    useEffect( () => {
        setEventObj(props.event);
        setVideoGalleryPopup(props.event!==false && props.event.post_id);
    }, [props.event])

    return (
        <>
            {VideoGalleryPopupElement()}
        </>
    )
}

export default VideoGallery;