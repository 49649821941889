import React from 'react';
import Iframe from 'react-iframe';
import { useLocation } from "react-router-dom";

const ExternalPage = (props) => {

    const search = useLocation().search;
    const title=new URLSearchParams(search).get("title");
    const url=atob(new URLSearchParams(search).get("url"));

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">{title}</h1>

                <Iframe 
                    url={url} 
                    width="100%" 
                    height="100%" 
                    // styles={{height: "100vh"}}
                />
            </div>
        </div>
    )
}

export default ExternalPage;