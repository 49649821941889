import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
// import { Col } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import Option from '../helpers/option';
import { saveProfile, resetProfileSaved, googleSignIn, googleSignOut, getSyncStatus, googleSync, getProfile, resetSyncError, removeGoogleEvents, resetMediaRemoved, addEndpoint } from '../slices/userSlice';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from 'react-toastify';
import '../css/profile.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { get_sync_status, set_google_token } from '../helpers/api.service';
import { getFieldValue, isProfileComplete, profileCompletionReminder } from '../helpers/generalFunctions';
import { userLogout, setNextReminder } from '../slices/userSlice';
import Alert from 'react-bootstrap/Alert';
import { confirmAlert } from 'react-confirm-alert';
import RemoveMedia from './RemoveMedia';
import { PUSH_NOTIFICATION_PUBLIC_KEY as publicKey } from '../helpers/constants';
import { getMobileOS, PWACanInstall, PWAIsInstalled } from '../helpers/browser';
import { Link } from 'react-router-dom';

import { ReactComponent as IconRemoveEvent } from '../icons/ico-remove-event.svg';
import { ReactComponent as IconRemoveMedia } from '../icons/ico-remove-media.svg';
import { ReactComponent as IconCalendarSync } from '../icons/ico-calendar-sync.svg';
import { ReactComponent as IconBell } from '../icons/ico-bell.svg';

const Profile = (props) => {

    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);

    const selectOptions = [
        { value: 'a', label: 'Platoon A', className: 'platoon-a' },
        { value: 'b', label: 'Platoon B', className: 'platoon-b' },
        { value: 'c', label: 'Platoon C', className: 'platoon-c' },
        { value: 'd', label: 'Platoon D', className: 'platoon-d' },
        { value: 'day', label: 'Daytime Shift', className: 'day' },
    ]

    const [firstName, setFirstName] = useState(userState.user.FirstName);
    const [lastName, setLastName] = useState(userState.user.LastName);
    const [email, setEmail] = useState(userState.user.Email);
    const [personalEmail, setPersonalEmail] = useState(getFieldValue(userState.user, 'Personal email'));
    const [mobilePhone, setMobilePhone] = useState(getFieldValue(userState.user, 'Mobile Phone'));
    const [address, setAddress] = useState(getFieldValue(userState.user, 'Address'));
    const [suburb, setSuburb] = useState(getFieldValue(userState.user, 'Suburb'));
    const [state, setState] = useState(getFieldValue(userState.user, 'State/Territory'));
    const [postcode, setPostcode] = useState(getFieldValue(userState.user, 'Postcode'));
    const [rank, setRank] = useState(getFieldValue(userState.user, 'Rank'));
    const [rankOption, setRankOption] = useState(typeof userState.user.rank_options !== 'undefined' ? userState.user.rank_options.map((option, index) => {

        if (typeof option.Value !== 'undefined') {
            let rank = option.Label;

            if (getFieldValue(userState.user, 'Rank') === rank) {
                // let dues = userState.user.dues[rank];
                return {
                    'label': rank,
                    'value': rank
                }
            }
        }
    }).filter((el) => el) : {});
    const [regno, setRegno] = useState(getFieldValue(userState.user, 'Reg No.'));
    const [stationNumber, setStationNumber] = useState(getFieldValue(userState.user, 'Station Number'));
    const [sync, setSync] = useState(userState.user.Sync === "1" ? true : false);
    const [platoons, setPlatoons] = useState(userState.user.Platoons ? userState.user.Platoons.map((platoon) => {
        return selectOptions.find(obj => {
            return obj.value === platoon;
        });
    }) : []);
    const [iOSNotification, setIOSNotification] = useState(localStorage.getItem("iOSNotification") === "true" ? true : false);

    const [validated, setValidated] = useState(false);

    const [remMediaPopup, setRemMediaPopup] = useState(false);

    const [pwaBtnEnabled, setPwaBtnEnabled] = useState(localStorage.getItem("pushnotification") !== null);

    const [sw, setSw] = useState(null);

    // console.log('pushnotification value', localStorage.getItem("pushnotification"));
    // console.log('pushnotification', localStorage.getItem("pushnotification")!==null);
    // const [profileReminder, setProfileReminder] = useState(true);

    const logout = () => {
        dispatch(userLogout());
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let selectedPlatoons = platoons && platoons.map((platoon) => {
            return platoon.value;
        })

        let params = {
            firstName,
            lastName,
            mobilePhone,
            address,
            suburb,
            postcode,
            personalEmail,
            rank,
            regno,
            stationNumber,
            sync: sync ? 1 : 0,
            platoons: selectedPlatoons ? selectedPlatoons : [],
        }

        if (state) {

            params['state'] = state;
        }

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        if (form.checkValidity() === true) {
            dispatch(saveProfile(params))
        }
    }

    const ProfileReminder = () => {
        if (!isProfileComplete(userState.user) && profileCompletionReminder(userState.user)) {
            console.log(userState.user);
            if (userState.user.profileReminderCount === 0) {
                return <Alert className="" variant="warning">Please complete your profile <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            } else {
                return <Alert className="" variant="warning">Please confirm that your profile details are correct <a href="#" className="btn-close" onClick={() => { dispatch(setNextReminder()); }}></a></Alert>;
            }
        }

        return <></>;
    }

    useEffect(() => {

        const getServiceWorker = async () => {
            let sw = await navigator.serviceWorker.getRegistration();
            setSw(sw);
        };

        getServiceWorker().catch(console.error);
    }, []);

    useEffect(() => {
        if (!userState.profile.isLoaded)
            dispatch(getProfile());
    }, [userState.profile.isLoaded])

    useEffect(() => {
        if (!userState.profile.statusChecked) {
            dispatch(getSyncStatus())
        }
    }, [userState.profile.statusChecked])


    useEffect(() => {
        if (userState.profile.googleSyncError) {
            dispatch(resetSyncError());
            toast.error(userState.profile.googleSyncError, {
                toastId: 'google-sync-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [userState.profile.googleSyncError])

    useEffect(() => {
        if (userState.profile.saved && !userState.profile.isLoading) {
            dispatch(resetProfileSaved());
            toast.success('Profile Saved', {
                toastId: 'save-profile-success',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (userState.profile.mediaRemoved && !userState.profile.mediaRemoving) {
            dispatch(resetMediaRemoved());
            toast.success('Media removed', {
                toastId: 'remove-media-success',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [userState.profile.saved, userState.profile.isLoading, userState.profile.mediaRemoved, userState.profile.mediaRemoving, dispatch])

    useEffect(() => {
        if (userState.profile.saveErrMsg) {
            dispatch(resetProfileSaved());
            toast.error(userState.profile.saveErrMsg, {
                toastId: 'save-profile-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (userState.profile.mediaRemoveError) {
            dispatch(resetProfileSaved());
            toast.error(userState.profile.mediaRemoveError, {
                toastId: 'remove-media-error',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [userState.profile.saveErrMsg, userState.profile.mediaRemoveError])

    useEffect(() => {
        if (userState.notification.enable == true) {
            setPwaBtnEnabled(true);
        }
    }, [userState.notification.enable])

    const BtnGoogleAuth = () => {

        const login = useGoogleLogin({
            onSuccess: codeResponse => {
                console.log(codeResponse)
                const code = codeResponse.code;

                dispatch(googleSignIn({ code }));
            },
            flow: 'auth-code',
            scope: 'https://www.googleapis.com/auth/calendar',
            // redirect_uri: 'https://ufuvic.mobi/server'
        });

        const logout = () => {
            dispatch(googleSignOut());
        }

        if (!sync) {
            return <></>
        }

        if (userState.profile.statusChecking) {
            // return <Button variant="secondary" disabled={true}>Loading ...</Button>
            <div className='g-sign-in-button' disabled={true}>
                <div className="content-wrapper">
                    <div className='logo-wrapper'>
                        <img src='https://developers.google.com/identity/images/g-logo.png' />
                    </div>
                    <span className='text-container'>
                        <span>Loading ...</span>
                    </span>
                </div>
            </div>
        }

        if (userState.profile.googleSignedIn) {
            // return <Button variant="secondary" onClick={() => logout()}>Google Sign Out</Button>
            return <div className='g-sign-in-button' onClick={() => logout()}>
                <div className="content-wrapper">
                    <div className='logo-wrapper'>
                        <img src='https://developers.google.com/identity/images/g-logo.png' />
                    </div>
                    <span className='text-container'>
                        <span>Google Sign Out</span>
                    </span>
                </div>
            </div>
        }

        return (
            // <Button variant="secondary" onClick={() => login()}>Google Sign In</Button>
            <div className='g-sign-in-button' onClick={() => login()}>
                <div className="content-wrapper">
                    <div className='logo-wrapper'>
                        <img src='https://developers.google.com/identity/images/g-logo.png' />
                    </div>
                    <span className='text-container'>
                        <span>Sign in with Google</span>
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <LoadingOverlay
                active={userState.profile.isLoading || userState.profile.mediaRemoving}
                // active={true}
                // spinner={<BounceLoader />}
                text=""
            >
            </LoadingOverlay>
            <div className="col-12">
                <h1 className="page-title">Profile</h1>
                <div className="alert-wrap">
                    <ProfileReminder />
                </div>
                <div className="profile-setting">
                    <Form noValidate validated={validated} className="form-profile col-12" onSubmit={handleSubmit}>
                        <div className="row">
                            <Form.Group className="mb-3 col-6" controlId="firstName">
                                <Form.Control type="text" placeholder="First Name*" required={true} value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter first name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="lastName">
                                <Form.Control type="text" placeholder="Last Name*" required={true} value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter last name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Control type="text" placeholder="Address" required={false} value={address} onChange={(event) => setAddress(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter address
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="suburb">
                            <Form.Control type="text" placeholder="Suburb" required={false} value={suburb} onChange={(event) => setSuburb(event.target.value)} />
                            <Form.Control.Feedback type="invalid">
                                Please enter suburb
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="row">
                            <Form.Group className="mb-3 col-6" controlId="postcode">
                                <Form.Control type="text" placeholder="Postcode" required={false} value={postcode} onChange={(event) => setPostcode(event.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter postcode
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="state">
                                <Form.Select aria-label="State/Territory" required={false} value={state} onChange={(event) => setState(event.target.value)} >
                                    <option value="">State/Territory</option>
                                    <option value="NT">NT</option>
                                    <option value="QLD">QLD</option>
                                    <option value="NSW">NSW</option>
                                    <option value="ACT">ACT</option>
                                    <option value="VIC">VIC</option>
                                    <option value="TAS">TAS</option>
                                    <option value="SA">SA</option>
                                    <option value="WA">WA</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please enter state
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Alert>Until further notice when making address changes please ensure you also notify the office on 94198811 or via email at membership@ufuvic.asn.au</Alert>
                            </div>
                        </div>
                        <div className="row">
                            <Form.Group className="mb-3 col-6" controlId="email">
                                <Form.Control type="text" placeholder="Email" required={false} value={email} onChange={(event) => setEmail(event.target.value)} readOnly={true} />
                                <Form.Text muted>
                                    Wild Apricot Login Email
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please enter email
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 col-6" controlId="personalEmail">
                                <Form.Control type="email" placeholder="Personal Email" required={false} value={personalEmail} onChange={(event) => setPersonalEmail(event.target.value)} />
                                <Form.Text muted>
                                    Personal / Private email address
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please enter personal email
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="mobilePhone">
                                <Form.Control type="text" placeholder="Mobile Phone" required={false} value={mobilePhone} onChange={(event) => setMobilePhone(event.target.value)} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter mobile phone
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3 col-6" controlId="rank">
                                <ReactSelect
                                    className=""
                                    options={typeof userState.user.rank_options !== 'undefined' ? userState.user.rank_options.map((option, index) => {

                                        if (typeof option.Value !== 'undefined') {
                                            let rank = option.Label;
                                            // let dues = userState.user.dues[rank];
                                            return {
                                                'label': rank,
                                                'value': rank
                                            }
                                        }
                                    }).filter((el) => el !== undefined) : []}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    onChange={(selected) => {
                                        console.log('selected', selected['value']);
                                        setRankOption(selected);
                                        setRank(selected['value']);
                                    }}
                                    allowSelectAll={false}
                                    value={rankOption}
                                    placeholder="Rank"
                                />
                                {userState.user.dues !== undefined && userState.user.dues[rank] !== undefined && <Form.Text className="text-larger"><strong>{userState.user.dues[rank]}</strong></Form.Text>}
                            </Form.Group>

                            <Form.Group className="mb-3 col-6" controlId="regno">
                                <Form.Control type="text" placeholder="Your Reg. No." required={false} value={regno} onChange={(event) => setRegno(event.target.value)} />
                                <Form.Text muted>
                                    Registration Number
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please enter reg no.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="stationNumber">
                                <Form.Control type="text" placeholder="Station Number" required={false} value={stationNumber} onChange={(event) => setStationNumber(event.target.value)} />
                                <Form.Text muted>
                                    eg. '85'
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please enter station number
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <Form.Group className="mt-3">
                            <InputGroup>
                                <Form.Label htmlFor="calendar-sync" className="col-12">Calendar Sync
                                    <Form.Check
                                        type="switch"
                                        id="calendar-sync"
                                        className=""
                                        label=""
                                        checked={sync}
                                        onChange={(event) => setSync(event.target.checked)}
                                    /></Form.Label>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <InputGroup>
                                <GoogleOAuthProvider clientId="960991971469-gt5ikupf0sss39sv5h1v3bdnb7cqo22u.apps.googleusercontent.com">
                                    {/* <GoogleLogin
                                        flow="auth-code"
                                        onSuccess={credentialResponse => {
                                            console.log(credentialResponse);
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    /> */}
                                    <BtnGoogleAuth />
                                </GoogleOAuthProvider>
                            </InputGroup>
                        </Form.Group>
                        {/* {console.log('signed',userState.profile.googleSignedIn)} */}
                        {userState.profile.googleSignedIn === true &&
                            <Form.Group className="mt-3">
                                <InputGroup>
                                    {sync && <Button variant="secondary" onClick={() => { dispatch(googleSync()) }} disabled={userState.profile.googleSynchronizing ? true : false}><IconCalendarSync /> {userState.profile.googleSynchronizing ? 'Synchronizing...' : 'Sync Now'}</Button>}
                                </InputGroup>
                            </Form.Group>}

                        <Form.Group className="mt-3">
                            {sync && <Button variant="secondary" onClick={() => {
                                confirmAlert({
                                    title: 'Confirmation',
                                    message: 'Proceed remove google events from Your account?',
                                    buttons: [
                                        {
                                            label: 'Yes', onClick: () => {
                                                dispatch(removeGoogleEvents())
                                            }
                                        },
                                        { label: 'No', onClick: () => { } }
                                    ]
                                });
                            }} disabled={userState.profile.googleRemoving ? true : false}><IconRemoveEvent /> {userState.profile.googleRemoving ? 'Clearing...' : 'Clear Google Calendar Items'}</Button>}
                            <Form.Text muted>
                                Clear all the items in your UFUVIC calendar that were imported from Google
                            </Form.Text>
                        </Form.Group>

                        <ReactSelect
                            className="mt-3"
                            options={selectOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                                Option
                            }}
                            onChange={(selected) => {
                                var newValue = selected.filter((obj) => { return platoons.indexOf(obj) === -1; });
                                var type = typeof newValue[0] !== 'undefined' && newValue[0].value === 'day' ? 'day' : 'platoon';

                                // let filtered = selected.filter((platoon) => {

                                //     if( type === 'day' && ['a','b','c','d'].includes(platoon.value) ){
                                //         return false
                                //     }
                                //     if( type === 'platoon' && platoon.value === 'day' ){
                                //         return false;
                                //     }

                                //     return platoon;
                                // })

                                let filtered = selected.filter((platoon) => {

                                    if (newValue[0] !== 'undefined' && newValue[0].value !== platoon.value) {
                                        return false
                                    }

                                    return platoon;
                                })

                                setPlatoons(filtered)
                            }}
                            allowSelectAll={true}
                            value={platoons}
                            placeholder="Select Platoons"
                        />

                        <Form.Group className="mt-3">
                            <Button variant="secondary" onClick={() => {
                                setRemMediaPopup(true);
                            }}><IconRemoveMedia /> Remove Media</Button>
                            <div>
                                <Form.Text muted className="col-12">
                                    Remove media files from server
                                </Form.Text>
                            </div>
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Link className="btn btn-secondary" to="/" onClick={(event) => {

                                event.preventDefault();

                                confirmAlert({
                                    title: 'Confirmation',
                                    message: 'Proceed to open this link in a separate browser window?',
                                    buttons: [
                                        {
                                            label: 'Yes', onClick: () => {
                                                // window.open('https://ufuvic.com.au/widget/ufuvic-events','popup', "width="+Screen.availWidth+",height="+ Screen.availHeight);
                                                // window.open('https://ufuvic.com.au/widget/ufuvic-events','_blank', 'toolbar=yes');
                                                // window.location.href='https://ufuvic.com.au/widget/ufuvic-events';

                                                let a = document.createElement('a');
                                                a.target = '_blank';
                                                a.href = 'https://admin.ufuvic.mobi/request-delete-account/';
                                                a.click();
                                            }
                                        },
                                        { label: 'No', onClick: () => { } }
                                    ]
                                });
                            }}><IconRemoveMedia style={{ color: '#fffff' }} color="white" width={16} height={16} /> <span className="title">Remove Account</span></Link>
                            <div>
                                <Form.Text muted className="col-12">
                                    Remove account from server (all data will be removed)
                                </Form.Text>
                            </div>
                        </Form.Group>

                        {/* <Form.Group className="mt-3">
                            <InputGroup>
                                <Form.Label htmlFor="ios-notification" className="col-12">IOS Notification
                                <Form.Check
                                    type="switch"
                                    id="cios-notification"
                                    className=""
                                    label=""
                                    checked={iOSNotification}
                                    onChange={(event) => {
                                        setIOSNotification(event.target.checked);
                                        localStorage.setItem("iOSNotification", event.target.checked)
                                    }}
                                /></Form.Label>
                            </InputGroup>
                        </Form.Group> */}

                        {getMobileOS() === 'iOS' && PWACanInstall && PWAIsInstalled && typeof sw !== 'undefined' && sw !== null &&
                            <Form.Group className="mt-3">
                                <Button variant={pwaBtnEnabled ? 'green' : 'secondary'} disabled={pwaBtnEnabled ? true : false} onClick={async () => {


                                    // console.log(sw);
                                    await sw.pushManager.getSubscription().then((subscription) => {
                                        return sw.pushManager.subscribe({
                                            userVisibleOnly: true,
                                            applicationServerKey: publicKey
                                        }).then((subscribe) => {
                                            let json = JSON.stringify(subscribe);

                                            dispatch(addEndpoint({ json }));
                                            localStorage.setItem("pushnotification", json);
                                            console.log('pushNotification', json);
                                        });
                                    })

                                }}><IconBell /> iOS Notification</Button>
                                <div>
                                    <Form.Text muted className="col-12">
                                        Enable iOS push notification for 'Home Screen' mode only
                                    </Form.Text>
                                </div>
                            </Form.Group>}

                        <Button variant="primary" className="mt-5" type="submit">
                            Save Profile
                        </Button>
                        <button className="btn mt-3 ms-auto me-auto d-block" onClick={(event) => { logout(); event.preventDefault() }}>Logout</button>

                    </Form>

                    <RemoveMedia open={remMediaPopup} setRemMediaPopup={setRemMediaPopup} />
                </div>
                {/* <div className="text-center">
                    {userState.user && userState.user.FirstName && <div className="profile-fullname">{userState.user.FirstName + ' ' + userState.user.LastName}</div>}
                    {userState.user && userState.user.Email && <div className="profile-email">{userState.user.Email}</div>}
                    <button className="btn btn-secondary mt-3" onClick={()=> logout() }>Logout</button>
                </div> */}
            </div >
        </div >
    )
}

export default Profile;