import React, {useState} from 'react';
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconCircle } from '../icons/ico-circle.svg';
import { ReactComponent as IconCheck } from '../icons/ico-check.svg';
import { getNotifications, notificationSeen } from '../slices/userSlice';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const Notification = (props) => {

    const userState = useSelector(state => state.user);
    const [id, setId] = useState(0);
    const dispatch = useDispatch();

    if(id){
        return <Navigate to={`/alert/${id}`} />
    }

    return (
        <div className="row">           
            <div className="col-12">
                <h1 className="page-title">Alerts</h1>

                <div className="btn-action-wrap">
                    <Form.Group className="mt-3">
                        <Button className="btn-clear-notification" variant="secondary" onClick={() => {
                            dispatch(notificationSeen({for:'all', type:'alert'})).then(() => {
                                dispatch(getNotifications());
                            });
                        }}><IconCheck /> Mark all as read</Button>
                    </Form.Group>
                </div>

                <div className="item-list list-alert">
                    {userState.notification.data !== null && userState.notification.data.map((notif, index) => {
                    return (<li key={index} onClick={() => { 
                                setId(notif.id); 
                                if( notif.seen === "0" || notif.seen === 0 ){
                                    dispatch(notificationSeen({id: notif.id, type:'alert'}));
                                }
                             }}>
                        <div className={(notif.seen === "0" ? 'highlight ' : '' ) + 'item-header' }><span className={(notif.seen === "0" ? 'highlight ' : '') + 'item-icon' }><IconCircle /></span><span className="sender">{notif.sender}</span><span className="post-time">{notif.time}</span></div>
                        <div className="item-desc highlight">{notif.title}</div>
                    </li>)
                    })}
                    {userState.notification.data === null || userState.notification.data !== null && userState.notification.data.length === 0 && <p className="text-center">No alert</p>}
                    {userState.notification.errorMsg !== null && <Alert className="mt-3" variant="warning">{userState.notification.errorMsg}</Alert>}
                </div>
            </div>
        </div>
    )
}

export default Notification;