import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import "react-datepicker/dist/react-datepicker.css";
import 'reactjs-popup/dist/index.css';
import '../css/note.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { removeMedia } from '../slices/userSlice';

const RemoveMedia = (props) => {

    const userState = useSelector(state => state.user);

    const dispatch = useDispatch();

    const [month, setMonth] = useState('');
    const [validated, setValidated] = useState(false);

    const closeViewPopup = () => {
        props.setRemMediaPopup(false);
        props.resetNote();
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let params = {
            month,
        }

        const form = event.currentTarget;
            
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        setValidated(true);

        if(form.checkValidity() === true) {
            dispatch(removeMedia(params))
        }
    }

    const RemoveMediaPopupElement = () => {
        
        if(!props.open){
            return (<></>);
        }
        
        return (
            <>
                <Popup className="view-note" open={props.open} position="center center" closeOnDocumentClick={false}>                
                    <a className="close-popup btn-close" onClick={()=> closeViewPopup()}></a>
                    <div className="form-wrap">
                        <h3>Remove Media</h3>                    
                        <div className="">
                            <Form noValidate validated={validated} className="form-profile col-12" onSubmit={handleSubmit}>
        
                                <Form.Group className="mb-3" controlId="month">
                                    <Form.Control type="number" placeholder="month" required={true} value={month} onChange={(event)=>setMonth(event.target.value)} />
                                    {/* <Form.Text muted>
                                        Please enter maximum age in month (Eg: 5)
                                    </Form.Text> */}
                                    <Form.Text muted>
                                        Please enter the total number of months. Any media older than this time period will be deleted.
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        Please enter value
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" className="mt-5" type="submit" disabled={userState.profile.mediaRemoving}>
                                    {userState.profile.mediaRemoving ? 'Removing...' : 'Remove Now'}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }

    return (
        <>
            {RemoveMediaPopupElement()}
        </>
    )
}

export default RemoveMedia;