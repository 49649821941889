import React, {useState} from 'react';
import { ReactAudioRecorder } from "@sarafhbk/react-audio-recorder";
import { ReactComponent as IconVoice } from '../icons/ico-voice.svg';
import { ReactComponent as IconStop } from '../icons/ico-stop.svg';
import { ReactComponent as IconClose } from '../icons/ico-close.svg';
import { ReactComponent as ImgRecording } from '../images/recording.svg';

const VoiceRecorder2 = (props) => {
  
  const [record, setRecord] = useState(null);
  const [stop, setStop] = useState(false);

  const save = async () => {
    // let file = File(mediaBlobUrl, 'test.mp3');

    const response = await fetch(record);
              
    const mediaBlob = await response.blob();

    console.log('mediaBlob', mediaBlob)
    const file = blobToFile(mediaBlob, "record-"+ Date.now() +".wav");

    console.log('file', file);
    props.addMediaToStorage(file);
    props.stopRecording();
    
  }

  const blobToFile = (blobData, filename) => {
    const fd = new FormData();
    fd.set('a', blobData, filename);
    return fd.get('a');
  }

  const blobToFile2 = (blobData, filename) => {
    const file = new File(
        [blobData],
        filename,
        { type: blobData.type }
    );

    return file;
  }

  return (
    <div className="voice-recorder">
      <ReactAudioRecorder
        render={ (obj) => {
          
          console.log('record',obj)
          const { status, startRecording, stopRecording, audioResult } = obj;

          console.log('voice url', audioResult)
          if(audioResult){
            setRecord(audioResult);
          }
          return(
          <>
            <div style={{
              display: 'grid',
              alignItems: 'center',
              justifyContent: 'center',
              placeItems: 'center'
            }}>
              {status==='recording' && <div className="recording-image"><ImgRecording /><ImgRecording /><span className="recording-msg">RECORDING...</span></div>}
              

              {/* {console.log('recordState', status )} */}
              {record && stop && <audio src={audioResult} controls autoPlay loop />}

              {stop && <button className="btn btn-primary d-block" onClick={save}>Save</button>}
              {/* <span id="add" onClick={save}><IconVoice /></span> */}
              {status==='idle' && <span className="msg-command">Press record button to start recording</span>}
              {status==='recording' && <span className="msg-command">Press stop button to stop recording</span>}

            </div>          
            <div className="fixed-add-wrap">
            {( status==='idle' || status==='stopped' ) && <span id="record-start" className="button" onClick={() => {startRecording(); setStop(false);}}><IconVoice /></span>}
            {status==='recording' && <span id="record-stop" className="button" onClick={() => {stopRecording(); setStop(true);}}><IconStop /></span>} 
            </div>
          </>
        )}}
      />
    </div>
  )
}

export default VoiceRecorder2;