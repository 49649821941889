import React, { useState, useEffect } from 'react';
import axios from "axios";
import useInfiniteScroll from "../helpers/useInfinite";
import {VIMEO_TOKEN} from "../helpers/constants";
import parse from 'html-react-parser';
import '../css/video.css';
import Alert from 'react-bootstrap/Alert';
import Vimeo from './Vimeo';
import OtherVideos from './OtherVideos';
import FlameVideos from './FlameVideos';

const Video = (props) => {

    const [tab, setTab] = useState('flames');

    return (
        <div className="row">
            <div className="col-12">
                <h1 className="page-title">Videos</h1>

                <div className="search-wrap">
                    <ul className="video-source">
                            <li className={tab === 'flames' ? 'active' : ''} onClick={() => setTab('flames')}>FORGED&nbsp;IN&nbsp;FLAMES</li>
                        <li className={tab  === 'vimeo' ? 'active' : ''} onClick={() => setTab('vimeo')}>VIMEO</li>
                        <li className={tab === 'other' ? 'active' : ''} onClick={() => setTab('other')}>OTHER</li>
                    </ul>
                </div>
                
                {tab === 'flames' && <FlameVideos />}
                {tab === 'vimeo' && <Vimeo />}
                {tab === 'other' && <OtherVideos />}
            </div>
        </div>
    )
}

export default Video;